@import '../Fullscreen.scss';

.ZoomedPdfViewerPage {
  @include Fullscreen();

  &__container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  &__moveDown,
  &__moveUp,
  &__moveLeft,
  &__moveRight {
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
  }

  &__image {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    // background-size: 100% auto;
  }

  &__toolbox {
    position: absolute;
    top: 85%;
    left: 85%;
    width: 10%;
    height: 10%;
    background-color: yellow;
    display: none;
  }

  &__thumbnail {
    max-width: 10%;
    height: 10%;
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
