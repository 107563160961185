@import '../Fullscreen.scss';

.PdfViewerPage {
  @include Fullscreen();

  & > .container-fluid {
    height: 100%;
  }

  &__toolbar {
    height: 5%;
  }

  &__footer {
    height: 5%;
  }

  &__movePrev,
  &__moveNext {
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
  }

  .popover {
    position: absolute;
    top: 10%;
    left: 5%;
    display: block;
  }
}
