.RiverInfoViewer {
  &__map {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  &__thumbnailArea {
    text-align: center;
  }

  &__thumbnail {
    // width: 74%;
    // height: auto;

    // height: 100%;
    // width: auto;
  }
}
