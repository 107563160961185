$display-height: 1920px;

.RootPageToolbar {
  height: 5vh;
  max-height: $display-height * 0.05;

  > .row {
    margin-top: 1em;
  }
}
