.MultiLineThumbnailSelector {
  display: block;
  width: 100%;
  border: none;
  border-radius: 1rem;
  padding: 0;
  box-shadow:
    rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
    rgba(0, 0, 0, 0.14) 0px 1px 1px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  background-color: inherit;

  margin-bottom: 2.4rem;

  &:focus {
    outline-width: 10px;
    outline-color: red;
    outline-style: solid;
  }

  & img {
    border-radius: 1rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  & > img {
    width: 100%;
  }

  &__caption {
    text-align: left;
    padding: 1rem;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;

    h3 {
      margin-top: 0;
    }

    .label {
      font-weight: 400;
    }
  }
}
